import { PT } from '../utils/available-languages'
import { BANGALO, CASA } from '../utils/available-accommodations'
import coverImage from '../assets/images/casa-do-soter/casa__Cover.webp'
import { CASA_PATH } from '../utils/routes'


export default function getData (language = PT) {
  return {
    id: CASA,
    coverImage,
    facilities: casaMessages.facilities[language],
    minPeople: 6,
    maxPeople: 8,
    title: casaMessages.title[language],
    description: casaMessages.description[language],
    infos: casaMessages.infos[language],
    priceTableHeaders: casaMessages.priceTableHeaders[language],
    priceTableLines: casaMessages.priceTableLines[language],
    path: CASA_PATH,
    related: [BANGALO],
  }
}

export const getTitle = (language = PT) => casaMessages.title[language]

export const getCoverImage = () => coverImage

const casaMessages = {
  title: {
    [PT]: 'Casa do Sóter',
  },
  facilities: {
    [PT]: {
      grill: 'Área gourmet com churrasqueira',
      woodOven: 'Forno à lenha',
      wifi: 'Wifi',
      tv: 'TV com Sky e Netflix',
      kitchen: 'Cozinha completa',
    }
  },
  description: {
    [PT]: `
      A casa destina-se a um grupo de até 8 pessoas que esteja procurando curtir a natureza com privacidade e conforto.
      Possui 3 quartos, sendo 2 suítes, e amplo mezanino com cama de casal, sofá, redes para descanso e TV. A cozinha é
      bem equipada em acessórios e eletrodomésticos e há também uma excelente Área Gourmet com churrasqueira e forno de pizza à lenha.
    `,
  },
  infos: {
    [PT]: [
      'Disponibilizamos roupa de cama e banho.',
      'Não permitimos animais domésticos.'
    ]
  },
  priceTableHeaders: {
    [PT]: [
      '',
      'Até 4 pessoas',
      'Até 6 pessoas',
      'Até 8 pessoas'
    ],
  },
  priceTableLines: {
    [PT]: [
      [
        {
          text: 'Pacote Final de Semana',
          description: '(sex à dom)'
        },
        {
          text: 'R$2.400,00',
        },
        {
          text: 'R$2.800,00',
        },
        {
          text: 'R$3.200,00',
        }
      ],
      [
        {
          text: 'Pacote Dias de Semana',
          description: '(seg à qui)'
        },
        {
          text: 'R$2.400,00',
        },
        {
          text: 'R$2.800,00',
        },
        {
          text: 'R$3.200,00',
        }
      ],
      [
        {
          text: 'Feriados',
        },
        {
          text: 'Consultar',
        },
        {
          text: 'Consultar',
        }
      ],
    ],
  },
}
